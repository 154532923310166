@import "~antd/dist/antd.css";

::selection {
  background: #228b22;
  color: #fff;
}

.logo {
  height: 60px;
  margin: 16px 5px;
}

.logo-bks {
  height: 140px;
  margin: 16px 50px;
}

.main-container {
  min-height: 100vh;
}

.content-container {
  padding: 24px 16px 0;
}

.header {
  background: #228b22;
  align-items: center;
  display: flex;
  justify-content: center;
}

.header .title {
  margin: 0;
  color: #fff;
  align-items: center;
}

.town-card {
  border: 1px solid #e3e3e3;
  text-align: center;
}

.footer {
  text-align: center;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #228b22;
}

@media screen and (max-width: 550px) {
  .header {
    padding: 0 15px;
  }

  .header .title {
    font-size: 20px;
  }

  .footer {
    padding: 24px 15px;
  }
}

@media screen and (max-width: 450px) {
  .header .title {
    font-size: 18px;
  }
}

@media screen and (max-width: 350px) {
  .footer {
    padding: 24px 10px;
  }
}
